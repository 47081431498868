
@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-bold-webfont.woff2') format('woff2'),
         url('ufessans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}


@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-semibold-webfont.woff2') format('woff2'),
         url('ufessans-semibold-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-book-webfont.woff2') format('woff2'),
         url('ufessans-book-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;

}




@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-bookitalic-webfont.woff2') format('woff2'),
         url('ufessans-bookitalic-webfont.woff') format('woff');
    font-weight: 'book';
    font-style: italic;

}



@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-italic-webfont.woff2') format('woff2'),
         url('ufessans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-light-webfont.woff2') format('woff2'),
         url('ufessans-light-webfont.woff') format('woff');
    font-weight: lighter;
    font-style: normal;

}




@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-lightitalic-webfont.woff2') format('woff2'),
         url('ufessans-lightitalic-webfont.woff') format('woff');
    font-weight: lighter;
    font-style: italic;

}




@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-medium-webfont.woff2') format('woff2'),
         url('ufessans-medium-webfont.woff') format('woff');
    font-weight: medium;
    font-style: medium;

}




@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-regular-webfont.woff2') format('woff2'),
         url('ufessans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-thinitalic-webfont.woff2') format('woff2'),
         url('ufessans-thinitalic-webfont.woff') format('woff');
    font-weight: thin;
    font-style: italic;

}


@font-face {
    font-family: 'ufes_sans';
    src: url('ufessans-thin-webfont.woff2') format('woff2'),
         url('ufessans-thin-webfont.woff') format('woff');
    font-weight: thin;
    font-style: normal;

}



